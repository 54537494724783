import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getVehicleTypeListRequest } from "../../../api/controller/RoadworthinessController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const roadworthinessListState = atom({
    key: 'roadworthinessListState',
    default: [],
})
export const roadworthinessVehicleTypeState = atom({
	key: 'roadworthinessVehicleTypeState',
	default:{
		data:[],
		ovf:false,
	}
})
export const roadworthinessProductionCompanyState = atom({
	key: 'roadworthinessProductionCompanyState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const roadworthinessDataStructureState = selector({
    key: 'roadworthinessDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Running #',
                name: 'RunningNumber',
                field: 'reference_number',
                type: 'text',
                order: { table: 0.5 }, 
                table: { maxWidth: 120, minWidth: 120 },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
            { 
                label: 'Customer Name',
                type: 'text',
                order: { form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
            { 
                label: 'Customer Email',
                type: 'email',
                order: { form: 1.1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
            { 
                label: 'Customer Number',
                type: 'number',
                order: { form: 1.1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Permanent Address',
                type: 'text',
                order: { form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, dupTo:'CurrentAddress' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Current Address',
                type: 'text',
                order: { form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Id Number',
                type: 'text',
                order: { table: 2.1, form: 4 }, 
                table: { maxWidth: 120, minWidth: 120 },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Phone Number',
                type: 'number',
                order: { form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, dupTo:'MobileNumber'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Mobile Number',
                type: 'number',
                order: { form: 6 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Vehicle Type',
                type: 'select',
                order: { table: 3, form: 7 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(roadworthinessVehicleTypeState),
				getRequest: getVehicleTypeListRequest,
            },
			{ 
                label: 'Reg #',
                name: 'RegistrationNumber',
                field: 'registration_number',
                type: 'text',
                order: { table: 2, form: 7.1 }, 
                table: { maxWidth: 120, minWidth: 120 },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Production Company',
                type: 'select',
                order: { form: 8 }, 
                rootValue: true,
                create: [{ enabled: true, size: { xs: 12, md: 6 }, creatable: true, dupTo:'EngineProductionCompany' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                list: get(roadworthinessProductionCompanyState),
            },
			{ 
                label: 'Model Number',
                type: 'text',
                order: { form: 9 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Chasis Number',
                type: 'text',
                order: { table: 4, form: 10 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Engine Production Company',
                type: 'select',
                order: { form: 11 }, 
                rootValue: true,
                create: [{ enabled: true, size: { xs: 12, md: 6 }, creatable: true }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                list: get(roadworthinessProductionCompanyState),
            },
			{ 
                label: 'Engine Number',
                type: 'text',
                order: { table: 4.1, form: 12 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Engine Capacity',
                type: 'text',
                order: { form: 13 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Maximum Carry Weight',
                type: 'text',
                order: { form: 14 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Seating Capacity',
                type: 'number',
                order: { form: 15 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Make Year',
                type: 'text',
                order: { form: 16 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'First Registered Date',
                type: 'datepicker',
                order: { form: 17 }, 
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Import Year',
                type: 'number',
                order: { form: 18 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Fuel Type',
                type: 'select',
                order: { form: 19 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'PETROL', value: 'PETROL' },
						{ label: 'DIESEL', value: 'DIESEL' },
                        { label: 'ELECTRIC', value: 'ELECTRIC' },
					]
				},
            },
			{ 
                label: 'Form Status',
                name : 'Status',
                field : 'status',
                type: 'select',
                order: { form: 20, table: 21 }, 
                table: { maxWidth: 140, minWidth: 140 },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, default: 'PENDING' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'PENDING', value: 'PENDING' },
						{ label: 'CHECKED', value: 'CHECKED' },
						{ label: 'CONFIRMED', value: 'CONFIRMED' },
					]
				},
            },
            // { 
            //     label: 'Start Date',
            //     field: 'from',
            //     type: 'text',
            //     order: { table: 18 }, 
            //     create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 6 }  }], 
            // },
            // { 
            //     label: 'Expiry Date',
            //     field: 'to',
            //     type: 'text',
            //     order: { table: 19 }, 
            //     create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 6 }  }], 
            // },
            { 
                label: 'Period',
                type: 'text',
                order: { table: 18 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 6 }  }], 
            },
            { 
                label: 'RW Status',
                field: 'rw_status',
                type: 'text',
                order: { table: 20 }, 
                table: { maxWidth: 120, minWidth: 120 },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 6 }  }], 
            },
			{ 
                label: 'Inspection Checks',
                field: 'checks',
                type: 'repeater',
                order: { form: 21 },
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnDisabled: true, addBtnDisabled: true, repeatLabel: false }], view: ['same_as:update.0'],
                children: [
                    { 
                		label: 'Name',
                		type: 'text',
                		order: { form: 1 }, 
                		create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 5 } }], view: ['same_as:update.0'], 
            		},
					{ 
                		label: 'Dhivehi Name',
                		type: 'text',
                		order: { form: 2 }, 
                		create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 5 }, attributes: { dir: 'rtl' } }], view: ['same_as:update.0'], 
            		},
                    {
                        label: 'Status',
                        type: 'switch',
                        order: { form: 3 },
                        create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 2 } }], view: ['same_as:update.0'],
                    }
					
                ]
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
