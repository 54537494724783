import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { themeTypeState } from "../../state/GlobalState";
// Redux
import { Link, withRouter } from "react-router-dom";
// availity-reactstrap-validation
// import { AvField, AvForm } from "availity-reactstrap-validation";
// action
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { completePasswordReset } from "../../api/controller/AuthController";
import { handleResponse } from "../../custom/helpers/HandleResponse";
import { passwordResetHashState } from "../../api/state/AuthState";

const ChangePassword = ({history}) => {
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [passwordResetHash, setPasswordResetHash] = useRecoilState(passwordResetHashState)
  const themeType = useRecoilValue(themeTypeState)
  const [form, setForm] = useState({
    password: '',
    confirmPassword: '',
  })

  useEffect(() => {
    if(!passwordResetHash){
      history.push("/")
    }
  }, [passwordResetHash, history]);

  // handleValidSubmit
  const handleValidSubmit = async(event) => {
    event.preventDefault()
    setSubmitDisabled(true)
    const response = await completePasswordReset({ ref: passwordResetHash, Password: form.password, Password_confirmation: form.confirmPassword })
    handleResponse(response)
    if(! (response?.Status === "error")){
      setPasswordResetHash(null)
      setSubmitDisabled(false)
      history.push("/login")
    } else {
      setSubmitDisabled(false)
    }
  }


  return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className={(themeType === 'dark' ? 'text-white' : 'text-primary')}>Reset your password !</h5>
                          <p>Change your password to continue.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={logo} alt="" className="rounded-circle" height="34" />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">

                      <Form className="form-horizontal mt-4" onSubmit={handleValidSubmit}>
                        <FormGroup>
                          <Label>Password</Label>
                          <Input name="Password" label="New Password" className="form-control" placeholder="Enter New Password" value={form.password} onChange={(e)=>setForm({...form, password: e.target.value})} type="password" required />
                        </FormGroup>
                        <FormGroup>
                          <Label>Password Confirmation</Label>
                          <Input name="Password_confirmation" label="Confirm Password" className="form-control" value={form.confirmPassword} onChange={(e)=>setForm({...form, confirmPassword: e.target.value})} placeholder="Confirm your Password" type="password" required />
                        </FormGroup>
                        <Row className="form-group">
                          <Col className="text-right">
                            <button className="btn btn-primary w-md waves-effect waves-light" disabled={submitDisabled} type="submit">Reset</button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p className={(themeType === 'dark' ? 'text-white' : '')}>Go back to{" "}<Link to="login" className={"font-weight-medium " + (themeType === 'dark' ? 'text-info' : 'text-primary')}>Login</Link>{" "}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
}
export default withRouter(ChangePassword);
