import { atom } from "recoil";
import VerticalLayout from "../components/VerticalLayout";
import HorizontalLayout from "../components/HorizontalLayout";

export const pageState = atom({
    key: 'pageState',
    default: null,
})

export const themeTypeState = atom({
    key: 'themeTypeState',
    default: localStorage.getItem('themeType') || 'mixed' // mixed, dark, light
})

export const lastAPIActionTimeState = atom({
    key: 'lastAPIActionTimeState',
    default: null,
})
export const pageActionsDisabled = atom({
    key: 'pageActionsDisabledState',
    default: true,
})

export const pageWebsocketsEnabledState = atom({
    key: 'pageWebsocketsEnabledState',
    default: false,
});

export const preloaderState = atom({
    key: 'preloaderState',
    default: true,
})

export const isMainLoadingState = atom({
    key: 'isMainLoadingState',
    default: false,
})

export const pageLoaderState = atom ({
    key: 'pageLoaderState',
    default: false,
})

export const tokenNeedsRefreshState = atom({
    key: 'tokenNeedsRefreshState',
    default: false,
})


export const notificationSettingsState = atom({
    key: 'notificationSettingsState',
    default: {
        enabled: false,
        enableViewAll: false, // enable this if there is a notification page
        notificationPage: '#', // page to redirect to for full notification list
        height: '230px',
    },
})

export const defaultAuthLayoutState = atom({
    key: 'defaultAuthLayoutState',
    default: VerticalLayout
})
export const defaultGuestLayoutState = atom({
    key: 'defaultGuestLayoutState',
    default: HorizontalLayout
})
export const megaMenuNameState = atom({
    key: 'megaMenuNameState',
    default: 'Mega Menu'
})

export const activeNavState = atom({
    key: 'activeNavState',
    default: {}
})

export const leftSidebarTypeState = atom({
    key: 'leftSidebarTypeState',
    default: 'default', // default, condensed
})