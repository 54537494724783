import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getInspectionListListRequest } from "../../../api/controller/VehicleTypeController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const vehicleTypeListState = atom({
    key: 'vehicleTypeListState',
    default: [],
})
export const vehicleTypeInspectionListState = atom({
	key: 'vehicleTypeInspectionListState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "md", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const vehicleTypeDataStructureState = selector({
    key: 'vehicleTypeDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Name',
                type: 'text',
                order: { form: 1, table: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Capacity On Sticker',
                type: 'select',
                order: { form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'Engine Capacity', value: 'engine_capacity' },
						{ label: 'Maximum Carry Weight', value: 'maximum_carry_weight' },
					]
				},
            },
			{ 
                label: 'Inspection List',
                type: 'select',
                order: { form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(vehicleTypeInspectionListState),
				getRequest: getInspectionListListRequest,
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
