import { handleBlobResponse } from "../../custom/helpers/HandleResponse";
import http from "../core/http";
const group = 'modelSpecifications';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const isDisplayedRequest =  async (data) => {
    return await http.post(`${group}/isDisplayed`, data).then(res => res.data).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getManufacturerListDataRequest =  async (data) => {
    return await http.post(`${group}/getManufacturerList`, data).then(res => res.data).catch(http.defaultCatch);
}

export const downloadReport = async (data) => {
    const response = await http({url: `${group}/downloadReport`, method: "POST", data: data, responseType: "blob"}).then(res => res.data).catch(http.defaultCatch);
    handleBlobResponse(response, (data.DownloadName || 'report.pdf'));
}