
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'


const notify = ({type="toastr", status, title="", message}) => {
    if( type === "toastr" ){
        toastr.options = {
            positionClass : 'toast-top-right',
            timeOut: 5000,
            extendedTimeOut : 1000,
            closeButton : true,
            progressBar : false,
            newestOnTop : false,
            showEasing : "swing",
            hideEasing : "linear",
            showMethod : "fadeIn",
            hideMethod : "fadeOut",
            showDuration : 300,
            hideDuration : 1000,
            preventDuplicates: true
        }
        if(status === "info")
            toastr.info(message,title)
        else if(status === "warning")
            toastr.warning(message,title)
        else if(status === "error")
            toastr.error(message,title)
        else
            toastr.success(message,title)
    }
}

export default notify;