import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/InspectionListController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const inspectionListListState = atom({
    key: 'inspectionListListState',
    default: [],
})


export const modalConfigDefault = { size: "xl", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const inspectionListDataStructureState = selector({
    key: 'inspectionListDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Name',
                type: 'text',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Inspection Checks',
                type: 'repeater',
                order: { form: 2 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false }], update: ['same_as:create.0'], view: ['same_as:create.0'],
                children: [
                    { 
                		label: 'Name',
                		type: 'text',
                		order: { form: 1 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 5 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					{ 
                		label: 'Dhivehi Name',
                		type: 'text',
                		order: { form: 2 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 5 }, attributes: { dir: 'rtl' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
                    { 
                		label: 'Order',
                		type: 'number',
                		order: { form: 3 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 2 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					
                ]
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
