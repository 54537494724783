import { handleBlobResponse } from "../../custom/helpers/HandleResponse";
import http from "../core/http";
const group = 'roadworthinesses';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}
export const findDataRequest =  async (data) => {
    return await http.post(`${group}/find`, data).then(res => res.data).catch(http.defaultCatch);
}

export const isDisplayedRequest =  async (data) => {
    return await http.post(`${group}/isDisplayed`, data).then(res => res.data).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}

export const renewDataRequest =  async (data) => {
    return await http.post(`${group}/renew`, data).then(res => res.data).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getVehicleTypeListRequest =  async (data) => {
	return await http.post(`${group}/getVehicleTypeList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getProductionCompanyListRequest =  async (data) => {
	return await http.post(`${group}/getProductionCompanyList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const downloadSticker = async (data) => {
    const response = await http({url: `${group}/downloadSticker`, method: "POST", data: data, responseType: "blob"}).then(res => res.data).catch(http.defaultCatch);
    handleBlobResponse(response, (data.DownloadName || 'sticker.png'));
}
export const downloadReport = async (data) => {
    const response = await http({url: `${group}/downloadReport`, method: "POST", data: data, responseType: "blob"}).then(res => res.data).catch(http.defaultCatch);
    handleBlobResponse(response, (data.DownloadName || 'report.pdf'));
}

export const uploadReport =  async (data) => {
    return await http.post(`${group}/uploadReport`, data).then(res => res.data).catch(http.defaultCatch);
}
export const sendEmailRequest =  async (data) => {
	return await http.post(`${group}/sendEmail`, data).then(res => res.data).catch(http.defaultCatch);
}



export const verifyRW =  async (data) => {
	return await http.post(`e/verifyRW`, data).then(res => res.data).catch(http.defaultCatch);
}