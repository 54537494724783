
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/UserController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const userListState = atom({
    key: 'userListState',
    default: [],
})
export const userRoleState = atom({
  key: 'userRoleState',
  default: {
    data: [],
    ovf: false,
  },
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = [
  {
    label: 'Active',
      size: 6,
      offset: 6,
      required: true,
      type: 'select',
      value: { label: "Yes", value: true },
      list: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
  }
]

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const userDataStructureState = selector({
    key: 'userDataStructureState',
    get: ({ get }) => (
        [{ 
            label: "Name",
            type: "text",
            order: { table: 1, form: 1 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 12, md: 4 } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },{ 
            label: "Email",
            type: "email",
            order: { table: 3, form: 3 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 12, md: 4 } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },
          {
            label: "Roles",
            subFields: { label: "name", value: "id" },
            type: "multiselect",
            order: { form: 5 },
            create: [{ enabled: true, size: { xs: 12, md: 12} }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: get(userRoleState),
          },{
            label: "Active",
            type: "switch",
            order: { table: 4, form: 6 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 6, md: 2 }, default: true }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: ['Yes', 'No'],
          },{ 
            label: "Password",
            type: "text",
            order: { form: 8 },
            create: [{ enabled: true, size: { xs: 12, md: 4 }, default: 'welcome@123', validations: 'readOnly', }],
          }].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})