import { cloneDeep } from "lodash";
import { atom, selector } from "recoil";
import { authState, permissionState } from "../api/state/AuthState";
import Login from "../app/guest/Login";
import ForgotPassword from "../app/guest/ForgotPassword";
import ChangePassword from "../app/guest/ChangePassword";
// import Home from "../app/auth/Home";
import Users from "../app/auth/users"
import Roles from "../app/auth/roles"
import Permissions from "../app/auth/permissions"
import NonAuthLayout from "../components/NonAuthLayout";
import ModelSpecifications from "../app/auth/modelSpecifications"
import Roadworthinesses from "../app/auth/roadworthinesses"
import VehicleTypes from "../app/auth/vehicleTypes"
import InspectionLists from "../app/auth/inspectionLists"
import ProductionCompanies from "../app/auth/productionCompanies"
import Verify from "../app/auth/Verify";



// import HorizontalLayout from "../components/HorizontalLayout";
// import GuestHome from "../app/guest/Home";


const navigation = [
    {
        name: 'Login',
        icon: 'bx bx-lock',
        link: '/login',
        defaultRoute: true,
        component: Login,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Forgot Password',
        icon: 'bx bx-lock',
        link: '/forgot-password',
        component: ForgotPassword,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Change Password',
        icon: 'bx bx-lock',
        link: '/change-password',
        component: ChangePassword,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Verify',
        icon: 'bx bx-lock',
        link: '/verify/:token',
        component: Verify,
        layout: NonAuthLayout,
        hidden: true,
        children: [],
    },
    {
        name: 'Main',
        icon: "bx bx-group",
        children: [
            { // Roadworthiness Route Begin
                name: 'Roadworthiness',
                icon: 'mdi mdi-sticker-outline',
                link: '/home',
                defaultRoute: true,
                component: Roadworthinesses,
                middleware: 'auth',
                permission: 'read-roadworthinesses',
                children: [],
            }, // Roadworthiness Route End
            { // ModelSpecification Route Begin
                name: 'Model Specification',
                icon: 'mdi mdi-form-dropdown',
                link: '/modelSpecifications',
                component: ModelSpecifications,
                middleware: 'auth',
                permission: 'read-modelSpecifications',
                children: [],
            }, // ModelSpecification Route End
        ]
    },
    {
        name: 'Master',
        icon: "bx bx-group",
        children: [
            { // ProductionCompany Route Begin
                name: 'Production Company',
                icon: 'mdi mdi-domain',
                link: '/productionCompanies',
                component: ProductionCompanies,
                middleware: 'auth',
                permission: 'read-productionCompanies',
                children: [],
            }, // ProductionCompany Route End
            { // InspectionList Route Begin
                name: 'Inspection List',
                icon: 'mdi mdi-format-list-bulleted',
                link: '/inspectionLists',
                component: InspectionLists,
                middleware: 'auth',
                permission: 'read-inspectionLists',
                children: [],
            }, // InspectionList Route End
            { // VehicleType Route Begin
                name: 'Vehicle Type',
                icon: 'mdi mdi-car-estate',
                link: '/vehicleTypes',
                component: VehicleTypes,
                middleware: 'auth',
                permission: 'read-vehicleTypes',
                children: [],
            }, // VehicleType Route End
        ]
    },
	// Navigations
    
    {
        name: 'ACL',
        icon: "bx bx-group",
        children: [
            {
                name: 'Users',
                icon: 'bx bx-group',
                link: '/acl/users',
                component: Users,
                middleware: 'auth',
                permission: 'read-users',
                children: []
            },
            {
                name: 'Roles',
                icon: 'mdi mdi-account-key-outline',
                link: '/acl/roles',
                component: Roles,
                middleware: 'auth',
                permission: 'read-roles',
                children: []
            },
            {
                name: 'Permissions',
                icon: 'bx bx-lock-open-alt',
                link: '/acl/permissions',
                component: Permissions,
                middleware: 'auth',
                permission: 'read-permissions',
                children: []
            },
        ]
    },
];


const megaMenu = [
    // {
    //     name: 'UI Components',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Lightbox',
    //             link: '/lightbox',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Range Slider',
    //             link: '/rangeslider',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // },
    // {
    //     name: 'Applications',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Ecommerce',
    //             link: '/ecommerce',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Calendar',
    //             link: '/calendar',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // },
    // {
    //     name: 'Extra Pages',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Maintenance',
    //             link: '/maintenance',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Coming Soon',
    //             link: '/comingsoon',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // }
]

const rawNavigationState = atom({
    key: 'rawNavigationState',
    default: navigation,
});


const rawMegaMenuState = atom({
    key: 'rawMegaMenuState',
    default: megaMenu,
});





export const navigationState = selector({
    key: 'navigationState',
    get: ({ get }) => {
        let tempNavigation = cloneDeep(get(rawNavigationState))
        const isAuth = get(authState)
        const permissions = get(permissionState)
        const parseModule = (nav) => {
            let returnVal = null
            if(nav.children.length > 0){
                if((isAuth && nav.middleware === "auth" && (!nav.permission || permissions.includes(nav.permission))) || (!isAuth && (nav.middleware === "guest" )) || !nav.middleware){
                    let tempChildren = []
                    nav.children.forEach((child)=>{
                        let temp = parseModule(child)
                        if(temp){
                            tempChildren = [...tempChildren, temp]
                        }
                    })
                    returnVal = {...nav, children: tempChildren}
                }
            } else {
                if((isAuth && nav.middleware === "auth" && (!nav.permission || permissions.includes(nav.permission))) || (!isAuth && (nav.middleware === "guest")) || !nav.middleware){
                    returnVal = nav
                }
            }
            return returnVal
        }
        // Remove Nav Items based on auth and permissions
        tempNavigation = tempNavigation.map((header)=>parseModule(header)).filter(n=>n!==null);
        // Remove Empty Parents
        const filterChildren = (nav) => {
            if(nav.children.filter(c=>c.children.length > 0).length > 0){
                nav.children = nav.children.map((child)=>{
                    child = filterChildren(child)
                    return child
                })
            } else {
                nav.children = nav.children.filter(c=>(c.children.length > 0 || c.component))
            }
            return nav
        }
        tempNavigation = tempNavigation.map((header)=>filterChildren(header));
        // Remove Empty Modules
        tempNavigation = tempNavigation.filter(header=>(header.children.length > 0 || header.component))
        return tempNavigation;
    }
})

export const megaMenuState = selector({
    key: 'megaMenuState',
    get: ({ get }) => {
        let tempMegaMenu = cloneDeep(get(rawMegaMenuState))
        const isAuth = get(authState)
        const permissions = get(permissionState)
        tempMegaMenu = tempMegaMenu.map((header)=>{
            header.children = header.children.filter(c=>(isAuth && c.middleware === "auth" && (!c.permission || permissions.includes(c.permission))) || (!isAuth && (c.middleware === "guest")) || !c.middleware)
            return header
        }).filter(header=>header.children.length > 0);
        return tempMegaMenu;
    }
})
