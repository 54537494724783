
import { Container } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { verifyRW } from "../../api/controller/RoadworthinessController";
import verified from "../../assets/images/verified.png";

const Verify = () => {

    const params = useParams();

    const verifyToken = async() => {
        const response = await verifyRW({ Token: params.token });
        if(response && response.Status === 'error') {
            setContent(
                <div id="error">
                    <h3>{response.Message}</h3>
                </div>
            )
        } else if(response && response.Status === 'success') {
            setContent(
                <div id="sticker" style={{maxWidth: '510px', padding: '50px', backgroundColor: 'white', borderRadius:'20px'}}>
                    <img src={verified} alt="verified" width="100px" />
                    <h3 style={{color: 'green', marginBottom: '30px'}}>Verified Vehicle</h3>
                    <div align="left" className="mb-5">
                        <p className="cb" style={{fontSize: '15px', marginBottom: '7px'}}><b>Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>{response.Data?.customer_name}</p>
                        <p className="cb" style={{fontSize: '15px', marginBottom: '7px'}}><b>Registration No.:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>{response.Data?.registration_number}</p>
                        <p className="cb" style={{fontSize: '15px', marginBottom: '7px'}}><b>Type:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>{response.Data?.vehicle_type?.name}</p>
                        <p className="cb" style={{fontSize: '15px', marginBottom: '7px'}}><b>Chasis:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>{response.Data?.chasis_number}</p>
                        <p className="cb" style={{fontSize: '15px', marginBottom: '7px'}}><b>Model:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>{response.Data?.model_number}</p>
                        <p className="cb" style={{fontSize: '15px', marginBottom: '7px'}}><b>Engine:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>{response.Data?.engine_number}</p>
                        <p className="cb" style={{fontSize: '15px', marginBottom: '7px'}}><b>Validity:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>{response.Data?.from} - {response.Data?.to}</p>
                    </div>
                    <p className="cb"><b>Verified by X1 Auto Spa (9999751, x1autospa@gmail.com)</b></p>
                </div>
            )
        } else {
            setContent(
                <div id="error">
                    <h3>Something went wrong</h3>
                </div>
            )
        }
    }

    useEffect(() => {
        verifyToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Validating = () => (
        <div id="validating">
            <h3>Validating...</h3>
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )

    const [content, setContent] = useState(Validating)

    return (
        <React.Fragment>
            <div className="page-content">
                <Container align="center" fluid>
                    {content}
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default Verify;

